var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l(([1, 2, 3]),function(j){return _c('div',{key:j,staticClass:"row"},_vm._l(([1, 2, 3]),function(i){return _c('div',{key:i,class:i === 2 && j === 1
          ? 'col-md-6 col-sm-6 col-12 m-b-2'
          : j === 2 && i !== 1
          ? 'd-none'
          : j === 2 && i === 1
          ? 'col-12 m-b-2'
          : j === 3 && i === 2
          ? 'col-md-6 col-sm-6 col-12 m-b-2'
          : 'col-md-3 col-sm-6 col-12 m-b-2'},[_vm._m(0,true)])}),0)}),0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media_skeletion m-1",attrs:{"cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('div',[_c('span',{staticClass:"sub-temp"})])])}]

export { render, staticRenderFns }