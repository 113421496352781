import axios from 'axios'

function getMediaListing(payload) {
  return axios.get('media/list', { params: payload })
}

function getMediaById(id) {
  return axios.get(`media/${id}`)
}

function reOrderMedia(id, payload) {
  return axios.post(`media/re_order/${id}`, payload)
}

function editMedia(id, payload) {
  return axios.put(`media/${id}`, payload)
}

function addMedia(payload) {
  return axios.post('media', payload)
}

function activeInactiveMedia(id) {
  return axios.put(`media/active-deactive/${id}`);
}

function deleteMedia(id) {
  return axios.delete(`media/${id}`);
}

export default {
  getMediaListing,
  getMediaById,
  reOrderMedia,
  editMedia,
  addMedia,
  activeInactiveMedia,
  deleteMedia,
}
