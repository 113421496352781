<template>
  <div>
    <confirm-disable
      ref="activeInactiveModal"
      :msg="activeInactiveMsg"
      @on-submit="activeInactiveMedia"
    />
    <confirm-delete
      ref="deleteModal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteMedia"
    />
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="3" md="3" sm="12">
            <label>Filter by Status</label>
            <v-select
              v-model="filterByStatus"
              :options="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="val => val.value"
              :clearable="false"
              placeholder="Select Status"
            />
          </b-col>
          <b-col cols="9" md="9" sm="12">
            <label for="" class="d-block invisible">Add Media</label>
            <b-button to="add" variant="primary" class="float-right">
              Add Media
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <div v-if="!items.length && !contentLoading" class="demo-spacing-0">
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span
            ><strong>{{ NotFound.MEDIA_NOT_FOUND }}</strong></span
          >
        </div>
      </b-alert>
    </div>
    <div v-for="(n, idx) in arrList" :key="idx">
      <draggable
        :key="idx"
        v-model="arrList[idx]"
        ghost-class="ghost"
        :animation="200"
        @change="changed($event, idx)"
      >
        <transition-group :key="idx" class="row">
          <div
            v-for="item in arrList[idx]"
            :key="item.id"
            :class="{
              'col-md-12': item.type === '1',
              'col-md-6': item.type === '1/2',
              'col-md-3': item.type === '1/4',
            }"
          >
            <div class="card theme-card theme_card_box">
              <img :src="item.bannerMedia" alt="location-image" />
              <span
                class="theme-is-active"
                :class="item.isActive ? 'bg-success' : 'bg-danger'"
                >{{ item.isActive ? "Active" : "In-Active" }}</span
              >
              <span class="theme-name">{{
                item.mediaLanguages && item.mediaLanguages[0]
                  ? item.mediaLanguages[0].title
                  : "-"
              }}</span>
              <b-dropdown
                v-if="checkAccess.Edit || checkAccess.delete"
                style="position: absolute; bottom: 0; right: 0;"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    stroke="white"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="
                    checkLoginRole() === RoleEnum.SuperAdmin
                      ? true
                      : checkAccess.Edit
                  "
                  :to="{ name: 'media-edit', params: { id: item.id } }"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    checkLoginRole() === RoleEnum.SuperAdmin
                      ? true
                      : checkAccess.Edit
                  "
                  @click="
                    activeInactiveMediaModal(
                      item.id,
                      item.mediaLanguages && item.mediaLanguages[0]
                        ? item.mediaLanguages[0].title
                        : '-',
                      item.isActive
                    )
                  "
                >
                  <feather-icon
                    :icon="`${item.isActive ? 'EyeOffIcon' : 'EyeIcon'}`"
                  />
                  <span class="align-middle ml-50">{{
                    item.isActive ? "Deactivate" : "Activate"
                  }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    checkLoginRole() === RoleEnum.SuperAdmin
                      ? true
                      : checkAccess.Edit
                  "
                  @click="
                    openDeleteModal(
                      item.id,
                      item.mediaLanguages && item.mediaLanguages[0]
                        ? item.mediaLanguages[0].title
                        : '-'
                    )
                  "
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <Skeleton v-if="contentLoading && !loadmore_media" />
    <div class="d-flex justify-content-center">
        <button
          v-if="arrList[0] && arrList[0].length < totalMedia"
          class="btn btn-primary"
          @click="loadMoreMedia()"
          :disabled='loadmore_media'>
          <div v-if="loadmore_media" class="spinner-border spinner-border-sm" />
          Load more
        </button>
      </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BAlert, BButton, BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import {
  resolveUserRoleIcon,
  resolveUserRoleVariant,
  resolveUserStatusVariant,
  resolveUserRoleName,
  resolveUserStatusName,
  perPageOptions,
  perPage,
  currentPage,
  RoleEnum,
  statusFilter,
  resConditionCheck,
  deleteConfirmMsg,
  NotFound,
} from "@/helpers/constant";
import accessRightCheck from "@/helpers/accessRightCheck";
import checkLoginRole from "@/helpers/checkLoginRole";
import mediaService from "@/services/banner-management/media/media.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from "@/services/errorHandler";
import draggable from "vuedraggable";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import ConfirmDelete from "../../../confirm-delete/ConfirmDelete.vue";
import ConfirmDisable from "../../../confirm-disable/ConfirmDisable.vue";
import vSelect from "vue-select";
import Skeleton from "./Skeleton.vue";


export default {
  components: {
    BDropdown,
    BDropdownItem,
    draggable,
    BAlert,
    Loader,
    BButton,
    ConfirmDelete,
    ConfirmDisable,
    BCard,
    BCardBody,
    vSelect,
    BRow,
    BCol,
    Skeleton,
  },

  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      totalMedia: 0,
      isSortDirDesc: true,
      items: [],
      arrList: [[]],
      // arr1: [[1, 2]],
      startIndex: 0,
      endIndex: 0,
      tableColumns: [{ key: "order" }, { key: "theme" }, { key: "actions" }],
      currentPage,
      perPage,
      perPageOptions,
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      resolveUserRoleName,
      resolveUserStatusName,
      resConditionCheck,
      statusFilter,
      deleteMsg: "",
      titleMsg: "",
      locationId: "",
      checkAccess: {},
      RoleEnum,
      NotFound,
      checkLoginRole,
      oldIndex: "",
      newIndex: "",
      mediaId: "",
      activeInactiveMsg: "",
      deleteMsg: "",
      filterByStatus: 1,
      loadmore_media: false,
    };
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    this.getMediaListing();
  },

  watch: {
    filterByStatus: {
      handler(val) {
        if(val === null) {
          this.filterByStatus = 1;
        }
        this.currentPage = 1;
        this.items = [];
        this.arrList[0] = [];
        this.totalMedia = 0;
        this.getMediaListing();
      }
    }
  },

  methods: {
    indexCheck(idx) {
      if (idx === 0) {
        return this.items.slice(0, 3);
      }
      if (idx === 1) {
        return this.items.slice(3, 4);
      }
      if (idx === 2) {
        return this.items.slice(4, 6);
      }
      if (idx === 3) {
        return this.items.slice(6, 8);
      }
      return "";
    },

    async getMediaListing() {
      this.contentLoading = true;
      const payload = {
        no_of_result: 8,
        page: this.currentPage,
        order: "ASC",
        order_by: "orderNumber",
        search: this.searchQuery,
        status_filter: this.filterByStatus,
      };
      await mediaService
        .getMediaListing(payload)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            if(res.data.data){
              res.data.data.forEach(it=>{
                this.arrList[0].push(it);
              });
            }
            /*this.arrList[0] = this.items.slice(0, 2);
            this.arrList[1] = this.items.slice(3, 4);
            this.arrList[2] = this.items.slice(4, 6);
            this.arrList[3] = this.items.slice(6, 8);
            this.arrList[4] = this.items.slice(8, 10); */
            this.totalMedia = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
            this.currentPage++;
          }
        })
        .catch((error) => {
          console.log("error",error)
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          this.loadmore_media = false;
          if (error.response.status === 404) {
            this.items = [];
            this.arrList[0] = [];
            this.totalMedia = 0;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          }
        });
    },

    async changed(evt, idx) {
      let payload;
      const {
        oldIndex,
        newIndex,
        element: { id },
      } = JSON.parse(JSON.stringify(evt.moved));
      console.log("idxidxidxidx",evt, idx)
      payload = {
        previous_index: oldIndex + 1,
        current_index: newIndex + 1,
      };
      try {
        // eslint-disable-next-line no-unused-vars
        const isActive = this.arrList[0].find(e => (e.id == id && e.isActive))
        if(isActive) {
          const res = await mediaService.reOrderMedia(id, payload);
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    activeInactiveMediaModal(id, name, is_active) {
      this.activeInactiveMsg = `Are you sure you want to ${
        is_active ? "deactivate" : "activate"
      } <strong>${name}</strong> media?`;
      this.mediaId = id;
      this.$refs.activeInactiveModal.show();
    },

    activeInactiveMedia(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.activeInactiveModal.loading = true;
      if (this.mediaId) {
        mediaService
          .activeInactiveMedia(this.mediaId)
          .then((res) => {
            this.$refs.activeInactiveModal.loading = false;
            this.$refs.activeInactiveModal.toggleModal = false;
            this.getMediaListing();
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          })
          .catch((error) => {
            this.$refs.activeInactiveModal.loading = false;
            this.$refs.activeInactiveModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    openDeleteModal(id, name) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${name} media?`;
      this.mediaId = id;
      this.$refs.deleteModal.show();
    },

    deleteMedia(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.deleteModal.loading = true;
      if (this.mediaId) {
        mediaService
          .deleteMedia(this.mediaId)
          .then((res) => {
            if (resConditionCheck(res.status)) {
              this.$refs.deleteModal.loading = false;
              this.$refs.deleteModal.toggleModal = false;
              this.items = this.items.filter(
                (item) => item.id !== this.mediaId
              );
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          })
          .catch((error) => {
            this.$refs.deleteModal.loading = false;
            this.$refs.deleteModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    async loadMoreMedia() {
      if(this.arrList[0] && this.arrList[0].length < this.totalMedia){
        this.loadmore_media = true;
        await this.getMediaListing();
        this.loadmore_media = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.theme-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 180px;
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
}
.theme-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.theme-card .theme-name {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  font-size: 14px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.theme-card .theme-is-active {
  position: absolute;
  max-width: 50%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  color: #fff;
  text-align: center;
  padding: 0px 5px;
  font-size: 12px;
  // opacity: 0.8;
  box-shadow: -3px 5px 8px 2px rgb(0 0 0 / 60%);
  font-weight: bold;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
}

.addBtn {
  position: relative;
  bottom: 50px;
  left: 900px;
}

.ghost {
  background: rgb(129, 125, 125);
  border: dotted 3px #fff;
  background-image: none;
}

.ghost:before {
  content: "Drag Here";
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 40%;
  left: 35%;
  font-family: "Times New Roman", Times, serif;
}
.ghost .theme-card {
  opacity: 0;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
